aside > .homenav {
  clear: both;
}

.slideDownSub {
  display: block;
  position: fixed;
  height: 100vh;
  width: 100vw;
  overflow: scroll;
  background: rgba(#00c6c6, 0.95);
  top: 0;
  left: -150%;
  transition: all ease 0.5s;
  padding-top: 60px;
  padding-bottom: 40px;
  box-sizing: border-box;

  &.show {
    // display: block;
    left: 0;
  }

  li {
    width: 100%;

    a {
      color: #fff;
      display: block;
      width: 100%;
      text-align: left;
      padding: 1rem;
      text-decoration: none;
      box-sizing: border-box;

      &:hover,
      &.active {
        background: #fff;
        color: #339999;
      }
    }
  }
}

.toggle-sub {
  display: block;
  padding: 1rem;
  color: #fff;
  background: #339999;
  position: relative;
  padding-left: 60px;
  min-height: 25px;
  z-index: 0;

  span {
    display: block;
    color: white;
    z-index: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &:after {
    content: url(/images/main_imgs/nav-icon.png);
    display: block;
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
    left: 1.5rem;
  }

  &:hover {
    cursor: pointer;
    background: #297171;
    color: #ffffff;
  }
}

.close-sub {
  position: absolute;
  top: 20px;
  right: 20px;
  background: transparent;
  padding: 0.25rem;
  height: 30px;
  z-index: 1;
  display: none;

  &:after {
    content: "";
    height: 1px;
    display: block;
    clear: both;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.8;

    .icon {
      &:after,
      &:before {
      }
    }
  }

  .menu-toggle-text {
    width: calc(100% - 40px);
    display: block;
    float: left;
    padding-right: 10px;
  }

  .icon {
    display: block;
    height: 5px;
    width: 30px;
    background: #fff;
    float: left;
    position: relative;
    margin-top: 10px;
    transition: all ease 0.5s;

    &:hover {
      cursor: pointer;
      background: #fff;

      &:after,
      &:before {
        background: #fff;
      }
    }

    &:before {
      content: "";
      display: block;
      width: 30px;
      height: 5px;
      position: absolute;
      background: #fff;
      top: -10px;
    }
    &:after {
      content: "";
      display: block;
      width: 30px;
      height: 5px;
      position: absolute;
      background: #fff;
      bottom: -10px;
    }
  }

  &.animate {
    position: fixed;
    top: 20px;
    right: 20px;
    display: block;

    .icon {
      animation: navIcon 0.8s forwards;

      &:after {
        visibility: visible;
        animation: navIconTop 0.8s forwards;
      }

      &:before {
        visibility: visible;
        animation: navIconBottom 0.8s forwards;
      }
    }
  }
}

@media screen and (min-width: 901px) {
  aside {
    margin: 0;
    padding: 0;
    border: none;
    float: left;
    width: 22%;
    background: transparent;
    clear: both;
  }

  .slideDownSub {
    position: static;
    background: transparent;
    overflow: hidden;

    &.show {
      overflow: hidden;
    }
  }

  .toggle-sub {
    display: none;
  }

  .homenav {
    border-top: none;
    margin: 0;
    padding: 0px;
    width: 100%;
    float: left;
    position: static;
    height: auto;

    &:after {
      content: "";
      display: block;
      clear: both;
      height: 1px;
      width: 1px;
    }
  }
  .homenav ul, ul.homenav {
    list-style: none;
    margin: 23px 0 0 0;
    padding: 0;
  }
  .homenav li {
    border-top: 1px solid #297171;
    margin: 0;
    padding: 0;
    outline: none;
    vertical-align: bottom; /* For IE */

    &:last-child {
      border-bottom: 1px solid #297171;
    }
  }
  .homenav a:link,
  .homenav a:visited {
    display: block;
    padding: 1% 0 1% 3%;
    border-left: 10px solid #5ed6d6;
    border-right: 1px solid #297171;
    color: #ffffff;
    font-size: 0.8em;
    line-height: 1.8em;
    font-weight: normal;
    background-color: #4babab;
    text-decoration: none;
    outline: none;
    transition: 0.1s linear;
    padding-right: 3px;
  }
  .homenav a:hover {
    display: block;
    border-left: 10px solid #5ed6d6;
    border-right: 1px solid #297171;
    color: #ffffff;
    background-color: #5ed6d6;
    text-decoration: none;
    outline: none;
    transition: 0.1s linear;
  }
  #homenav_active a:link,
  #homenav_active a:visited,
  #homenav_active a:hover {
    background-color: #297171;
    color: #ffffff;
    border-left: 10px solid #297171;
    text-decoration: none;
    outline: none;
  }
}

.left-aside {
	.share-links {
    clear: both;
		padding: 1rem;

    h3 {
      margin-bottom: 0.5rem;
    }
	}
}