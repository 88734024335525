@import '../base/resets';


@import '../core/site';
@import '../core/header';
@import '../core/search';
@import '../core/nav';
@import '../core/sidenav';
@import '../core/content';
@import '../core/footer';
@import '../core/news';
@import '../core/media';
@import '../core/fb';
@import '../modules/forms';
@import '../modules/covid_19';

@import '../modules/page-authors';

#unsubscribe, #subscribe {
	.buttonscontainer {
		display: none;
	}
}

#unsubscribe {
	.btn-success {
		width: 200px;
	}
}

#site-map {
	.sitemap {
		display: block;
		clear:both;
		padding-left: 30px;

		ul {
			padding-left: 30px;
		}

		li {
			display: block;
			clear:both;

			a {
				color: #4babab;
			}
		}
	}
}

h1 {
	margin: 1%;
	width: auto;
}

h2 {
	margin-left: 1%;
}


@media screen and (max-width:901px) {
aside > .homenav {
		// display: none;
	}
.side_nav_button{
		margin: 50px auto;
	}
}
.homemenubox_courses {
	margin: 0 0 1% 0;
	padding: 0;
	overflow: none;
	float: left;
	background-image:url(/images/main_imgs/gall_menu_trauma.jpg);
	background-repeat: no-repeat;
	background-size: 100%;
}
.homemenubox_tutorials {
	margin: 0 0 1% 0;
	padding: 0;
	overflow: none;
	float: left;
	width: 100%;
	background-image:url(/images/main_imgs/gall_menu_chest.jpg);
	background-repeat: no-repeat;
	background-size: 100%;
}
.homemenubox_gall {
	margin: 0 0 1% 0;
	padding: 0;
	overflow: none;
	float: left;
	background-image:url(/images/main_imgs/gall_menu_brain.jpg);
	background-repeat: no-repeat;
	background-size: 100%;
}
.homemenubox_tutorials h2, .homemenubox_gall h2, .homemenubox_courses h2{
	margin:0;
	padding: .7% 1% .5% 2%;
	width: 97%;
	font-size: 1.2em;
	font-weight:bold;
	color: #ffffff;
	background: #5ed6d6; /* Old browsers */
	background: -moz-linear-gradient(top,  #5ed6d6 0%, #4babab 45%, #4babab 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  #5ed6d6 0%,#4babab 45%,#4babab 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  #5ed6d6 0%,#4babab 45%,#4babab 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5ed6d6', endColorstr='#4babab',GradientType=0 ); /* IE6-9 */
}
.homeinfo	{
	margin: 0;
	padding: 0 2%;
	width: 96%;
}
.homeinfo ul {
	margin: 0;
	padding: 1% 2% 2.5% 4%;
	border: none;
}
.homeinfo li {
	margin: 0;
	padding: .5% 1% 2.5% 1%;
	color: #ffffff;
	list-style: url(/images/main_imgs/squ_bull_3.gif);
}
.homeinfo li a:link, .homeinfo li a:visited{
	margin:0;
	padding:0;
	color: #ffffff;
	text-decoration: underline;
	outline: none;
}
.homeinfo li a:hover, .homeinfo li a:active{
	margin:0;
	padding: 0;
	color: #fcd001;
	text-decoration: underline;
	outline: none;
}
.homedescrip	{
	margin:0;
	padding: 0 1%;
	width: 96%;
}
.homeinfo h3 {
	margin: 2% 0 0 0;
	padding: 0 1%;
	color: #ffffff;
}
.homedescrip p {
	padding: 0 2%;
	margin: 1% 0 0 0;
	width: 96%;
	color: #ffffff;
	font-size: 1em;
}
.homedescrip li a:link, h4 a:visited{
	margin:0;
	padding:0;
	line-height:1.8em;
	color: #999999;
	text-decoration: underline;
	outline: none;
}
.homedescrip li a:hover, h4 a:active{
	margin:0;
	padding:0;
	line-height:1.8em;
	color: #5ed6d6;
	text-decoration: underline;
	outline: none;
}

#mail-success {

	.buttonscontainer {
		display: none;
	}
}