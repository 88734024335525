
.hidden {
  display: none;
}

input[name="main-email"] {
  display: none;
}

form.contentForm{
  box-sizing: border-box;
  padding-left: 1%;
  width: 100% - 2%;
  @media only screen and (min-width: 768px) {
      padding-right: 40%;
      width: 100%;
  }
}

.g-recaptcha {
  margin-bottom: 20px;
}

form * {
  box-sizing: border-box;
}

.message.success {
  font-weight: 700;
  font-size: 16px;
}

form .errors {
  li {
    color: red;
    font-weight: 700;
    margin-bottom: 20px;
  }
}
form .legend {
  margin: 1.5rem 0 0.75rem; }

form .row {
  position: relative;
  padding-bottom: 0.75rem; 
  clear:both;
}
  form .row:last-child {
    padding: 0; }
  form .row.field-error .input,
  form .row.field-error .textarea,
  form .row.field-error .select, form .row.field-error .author-widget .author-select, .author-widget form .row.field-error .author-select, form .row.field-error .archive-widget .archive-select, .archive-widget form .row.field-error .archive-select,
  form .row.field-error .archive-widget .select-wrapper, .archive-widget form .row.field-error .select-wrapper {
    border-color: #e74c3c;
    background: rgba(231, 76, 60, 0.05);
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    -webkit-animation: hippyshake 0.15s linear;
    animation: hippyshake 0.15s linear;
    -webkit-animation-iteration-count: 0.5s;
    animation-iteration-count: 0.5s; }
  form .row.field-error .select, form .row.field-error .author-widget .author-select, .author-widget form .row.field-error .author-select, form .row.field-error .archive-widget .archive-select, .archive-widget form .row.field-error .archive-select,
  form .row.field-error .archive-widget .select-wrapper, .archive-widget form .row.field-error .select-wrapper {
    color: #717f81; }
  form .row.field-error .select-wrapper:after {
    border-top-color: #e74c3c; }
  form .row.field-success .input,
  form .row.field-success .textarea,
  form .row.field-success .select, form .row.field-success .author-widget .author-select, .author-widget form .row.field-success .author-select, form .row.field-success .archive-widget .archive-select, .archive-widget form .row.field-success .archive-select,
  form .row.field-success .archive-widget .select-wrapper, .archive-widget form .row.field-success .select-wrapper {
    border-color: #2ecc71;
    background: rgba(46, 204, 113, 0.05); }
  form .row.field-success .select, form .row.field-success .author-widget .author-select, .author-widget form .row.field-success .author-select, form .row.field-success .archive-widget .archive-select, .archive-widget form .row.field-success .archive-select,
  form .row.field-success .archive-widget .select-wrapper, .archive-widget form .row.field-success .select-wrapper {
    color: #717f81; }
  form .row.field-success .select-wrapper:after {
    border-top-color: #2ecc71; }
  form .row.required > label:after {
    content: '\00a0*';
    color: #4babab; }
  form .row > label {
    font-size: 1.1rem;
    padding-right: 1rem; }

form .helper {
  clear: both;
  font-size: 0.9rem;
  color: #88929b;
  font-style: italic; }
  form .helper.invalid {
    display: block;
    color: #e74c3c; }
  form .helper.valid {
    display: none;
    color: #2ecc71; }

form .input,
form .textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 7px 1rem 2px 1rem;
  border: 1px solid #4babab;
  font-size: inherit;
  font-family: inherit;
  outline: none;
  border-radius: 4px;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }
  form .input:focus,
  form .textarea:focus {
    border-color: #4babab; }

form .textarea {
  display: block;
  height: 120px;
  resize: none;
  padding: 0.5rem 1rem; }
  form .textarea.small {
    height: 80px; }

form .input,
form.contentForm input[type="submit"] {
  height: 40px;
  line-height: 1; }

form.contentForm input[type="submit"] {
  width: 50%;
  outline: none;
  float: none;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }
  


form .radio-list {
  padding-top: 0.5rem; }
  form .radio-list label {
    display: block;
    float: left;
    width: 48%;
    margin-left: 4%; }
    form .radio-list label:first-child {
      margin: 0; }
    form .radio-list label span {
      display: block;
      height: 40px;
      line-height: 40px;
      font-size: 1.1rem;
      text-align: center;
      color: #fff;
      background: #394A59;
      border-radius: 4px;
      cursor: pointer;
      -webkit-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out; }
      form .radio-list label span:hover {
        background: #4babab; }
    form .radio-list label .radio:checked + span {
      background: #9ca81f; }

form .checkbox-list {
  padding-top: 0.5rem; }
  form .checkbox-list label {
    display: block; }
    form .checkbox-list label span {
      display: block;
      position: relative;
      padding-left: 40px;
      margin: 0 0 0.5rem 0;
      line-height: 1.4;
      cursor: pointer; }
      form .checkbox-list label span:before {
        content: '+';
        position: absolute;
        top: 0;
        left: 0;
        width: 25px;
        height: 25px;
        line-height: 22px;
        margin-right: 10px;
        color: #fff;
        font-weight: 700;
        text-align: center;
        border: 1px solid #eee;
        border-radius: 4px;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out; }
      form .checkbox-list label span:hover:before {
        margin-right: 18px;
        border-color: #394A59; }
    form .checkbox-list label .checkbox:checked + span:before {
      border-color: #394A59;
      background: #394A59; }


