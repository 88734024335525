/******************************** CSS for NEWSCOL *******************************/
.newscol	{
	margin:	1.3% 1% 0 0;
	padding: 0;
	width: 33%;
	border: solid 1px #4babab;
	font-size: 1em;
	text-align: left;
	position: absolute;
	right: 0;
	top: 0px;
	background-color: #ffffff;
}
.newscol ul {
	list-style: none;
	padding: 1% 4% 2% 3%;
	margin: 0;
}
.newscol li {
	text-align: left;
	list-style: url(/images/main_imgs/squ_bull_3.gif);
	padding: 0;
	margin: 0 0 3px 12px;
	clear: both;
}
.newscol h2	{
	padding: 1% 2%;
	margin: 0 0 2% 0;
	width: 96%;
	font-size: 1.2em;
	font-weight:bold;
	color: #ffffff;
	background-color: #4babab;
	background-image:url(/images/main_imgs/hmblu_bkgd.jpg);
	background-repeat:repeat-x;
}
.newscol h3	{
	padding: 2% 2% 1% 2%;
	margin: 0;
	width: 96%;
	font-size: 1.1em;
	font-weight: bold;
	border-bottom: solid 1px #4babab;
}
.newscol p	{
	padding: 0 2% 1% 2%;
	margin: 0 0 4% 0;
	width: 96%;
	font-size: 1em;
}
.newscol a:link {
	margin: 0;
	padding: 0;
	color: #297171;
	text-decoration: underline;
	outline: none;
}
.newscol a:visited{
	margin: 0;
	padding: 0;
	color: #297171;
	text-decoration: underline;
	outline: none;
}
.newscol a:hover{
	margin: 0;
	padding: 0;
	color: #5ed6d6;
	text-decoration: underline;
	outline: none;
}
.fb-page {
	margin: 1%;
	width:98%;
}


.adholder {
	width: 120px;
	margin:auto;
	display: block;
}


.fb_iframe_widget {
	span {
		width: 100%!important;

		iframe {
			max-width: 100%;
			width: 100%!important;
		}
	}
}
/******************************** End of CSS for NEWSCOL *******************************/
  