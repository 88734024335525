
/******************************** CSS FOR FOOTER LAYOUT *******************************/
footer {
	margin: 0;
	padding: 0 0 1% 0;
	width: 100%;
	color: #ffffff;
	background-color: #333333;
	background-image: url(/images/main_imgs/body_bckgd.jpg);
	background-repeat: repeat-x;
	display: block;
	float: none;
	overflow: hidden;
}
.footer_group {
	margin: 0;
	padding: 0;
	width: 50%;
	float: left;
}
.footer_box {
	margin: 0;
	padding: 1% 1% 2% 1%;
	width: 48%;
	border: none;
	float: left;
	display: block;
}
/******************************** END OF CSS FOR FOOTER LAYOUT *******************************/

/******************************** CSS FOR FOOTER STYLING *******************************/
.footer_box h5 {
	padding: 0 0 0 1%;
	margin: 0;
	color: #ffffff;
	font-family: Arial,'Century Gothic', Helvetica, sans-serif;
	text-align: left;
	font-size: 1em;
	font-weight: bold;
}
.footer_box h5 a:link, a:visited {
	padding: 0;
	margin: 0;
	font-family: Arial,'Century Gothic', Helvetica, sans-serif;
	font-weight: normal;
	outline: none;
	text-align: left;
	color: #ffffff;
	font-size: 1em;
	font-weight: bold;
	text-decoration: none;
	background: none;
}
.footer_box h5 a {

	&:hover, &:active {
		padding: 0;
		margin: 0;
		font-family: Arial,'Century Gothic', Helvetica, sans-serif;
		font-weight: normal;
		outline: none;
		text-align: left;
		color: #4babab;
		font-size: 1em;
		font-weight: bold;
		text-decoration: underline;
		background: none;
	}
}

.footer_box p {
	margin: 0;
	font-family: Arial,'Century Gothic', Helvetica, sans-serif;
	font-size: .9em;
	font-weight: normal;
	outline: none;
	text-align: left;
	color: #ffffff;
}
/*
#footer_news {
	display: block;
	margin: 7% 0;
    float: left;
    width: calc(100% - 17%);
    padding-top: 10px;
    padding-left: 5px;
    box-sizing: border-box;
}
*/
.footer_box p a:link {
	padding: 0;
	margin: 0;
	font-family: Arial,'Century Gothic', Helvetica, sans-serif;
	font-weight: normal;
	outline: none;
	text-align: left;
	color: #5ed6d6;
	font-weight: bold;
	text-decoration: underline;
	background: none;
}
.footer_box p a:visited {
	padding: 0;
	margin: 0;
	font-family: Arial,'Century Gothic', Helvetica, sans-serif;
	font-weight: normal;
	outline: none;
	text-align: left;
	color: #5ed6d6;
	font-weight: bold;
	text-decoration: underline;
	background: none;
}
.footer_box p a:hover {
	color: #4babab;
	text-decoration: underline;
	background: none;
}
.footer_box p a:active {
	color: #4babab;
	text-decoration: underline;
	background: none;
}
.footer_text a:link {
	padding: 0;
	margin: 0;
	font-family: Arial,'Century Gothic', Helvetica, sans-serif;
	font-weight: normal;
	outline: none;
	text-align: left;
	color: #5ed6d6;
	font-weight: bold;
	text-decoration: underline;
	background: none;
}
.footer_text a:visited {
	padding: 0;
	margin: 0;
	font-family: Arial,'Century Gothic', Helvetica, sans-serif;
	font-weight: normal;
	outline: none;
	text-align: left;
	color: #5ed6d6;
	font-weight: bold;
	text-decoration: underline;
	background: none;
}
.footer_text a:hover {
	color: #4babab;
	text-decoration: underline;
	background: none;
}
.footer_text a:active {
	color: #4babab;
	text-decoration: underline;
	background: none;
}
/*
.footer_fb{
	display: block;
	margin: 0;
	float:left;
	width: calc(100% - 17%);
	padding-top:10px;
	padding-left: 5px;
	box-sizing: border-box;
}
*/
.footer_text {
	display: block;
	float:left;
	width: calc(100% - 17%);
	padding-top:10px;
	padding-left: 5px;
	box-sizing: border-box;
}

/*
.footer_fb a:link {
	padding: 0;
	margin: 0;
	font-family: Century Gothic, Trebuchet MS, Arial, Helvetica, sans-serif;
	font-weight: normal;
	outline: none;
	text-align: left;
	color: #5ed6d6;
	font-weight: bold;
	text-decoration: underline;
	background: none;
}
.footer_fb a:visited {
	padding: 0;
	margin: 0;
	font-family: Century Gothic, Trebuchet MS, Arial, Helvetica, sans-serif;
	font-weight: normal;
	outline: none;
	text-align: left;
	color: #5ed6d6;
	font-weight: bold;
	text-decoration: underline;
	background: none;
}
.footer_fb a:hover {
	color: #4babab;
	text-decoration: underline;
	background: none;
}
.footer_fb a:active {
	color: #4babab;
	text-decoration: underline;
	background: none;
}*/
a.imageswap_fb {
	margin: 2% 0;
	width: 17%;
	padding-bottom: 17%;
	background-image: url(/images/main_imgs/fb_link_img.png);
	background-position: 0 0;
	background-size: 200%;
	background-repeat: no-repeat;
	display: block;
	float: left;
}

.footer_fb_wrapper, .footer_nl_wrapper{
	display: block;
	box-sizing: border-box;
	margin: 0.5rem;

}



.footer_nl a:link {
	padding: 0;
	margin: 0;
	font-family: Arial,'Century Gothic', Helvetica, sans-serif;
	font-weight: normal;
	outline: none;
	text-align: left;
	color: #5ed6d6;
	font-size: .9em;
	font-weight: bold;
	text-decoration: underline;
	background: none;
}
.footer_nl a:visited {
	padding: 0;
	margin: 0;
	font-family: Arial,'Century Gothic', Helvetica, sans-serif;
	font-weight: normal;
	outline: none;
	text-align: left;
	color: #5ed6d6;
	font-size: .9em;
	font-weight: bold;
	text-decoration: underline;
	background: none;
}
.footer_nl a:hover {
	color: #4babab;
	text-decoration: underline;
	background: none;
}
.footer_nl a:active {
	color: #4babab;
	text-decoration: underline;
	background: none;
}
a.imageswap_fb:hover {
	background-position: 100% 0;
	background-size: 200%;
}
a.imageswap_news {
	margin: 2% 0;
	width: 17%;
	padding-bottom: 17%;
	background-image: url(/images/main_imgs/news_link.png);
	background-position: 0 0;
	background-size: 200%;
	background-repeat: no-repeat;
	display: block;
	float: left;
	clear:both;
}
a.imageswap_news:hover {
	background-position: 100% 0;
	background-size: 200%;
}
a.footer_trauma_link {
	margin: auto;
	width: 100%;
	padding-bottom: 34.9%;
	background-image: url(/images/main_imgs/footer_trauma_link.png);
	background-position: 0 0;
	background-size: 200%;
	background-repeat: no-repeat;
	display: block;
}
a.footer_trauma_link:hover {
	background-position: 100% 0;
	background-size: 200%;
}
a.footer_ctb_link {
	margin: auto;
	width: 100%;
	padding-bottom: 34.9%;
	background-image: url(/images/main_imgs/footer_ct_link.png);
	background-position: 0 0;
	background-size: 200%;
	background-repeat: no-repeat;
	display: block;
}
a.footer_ctb_link:hover {
	background-position: 100% 0;
	background-size: 200%;
}
.footerbottom {
	padding: 2% 0;
	margin: 0;
	font-size: .7em;
	text-align: center;
	color: #ffffff;
}
.footerbottom a:link {
	color: #ffffff;
}
.footerbottom a:visited {
	color: #ffffff;
}
.footerbottom a:hover {
	color: #4babab;
}
/******************************** END OF CSS FOR FOOTER STYLING *******************************/

/******************************** MEDIA QUERIES FOR FOOTER LAYOUT *******************************/
@media only screen and (max-width:800px) {
.footer_group {
	width: 100%;
	border-bottom: 2px solid #999999;
	box-sizing: border-box;
	padding: 0 0.5em;
}
.footer_box {
	border-bottom: none;
}
.footer_box p {
	font-size: .8em;
}
}


@media only screen and (max-width:830px) {

	.footer_fb_wrapper, .footer_nl_wrapper {
		display: block;
		width: 100%;

		&.footer_nl_wrapper {
			width: 43px;
		}

		a {
			float:none;
			width: 100%;
			padding-bottom: 100%;
		}


		.footer_text{
			display: none;

			a {
				display: none;
			}
		} 
	}
}



@media only screen and (max-width:479px) {
.footer_box {
	width: 98%;
	border-bottom: 2px solid #999999;
}
.footer_group {
	border-bottom: none;
}
.footer_box p {
	padding: 0 1%;
}
}

/******************************** END OF MEDIA QUERIES FOR FOOTER LAYOUT *******************************/