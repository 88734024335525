/**************************  CSS FOR GENERAL STYLING  ******************************/

html {
  position: relative;
  z-index: 0;
}

h1 {
  .h2 {
    font-size: 1.3rem;
  }
}
::-moz-selection {
  background-color: #4babab;
  color: #ffffff;
}
::selection {
  background-color: #4babab;
  color: #ffffff;
}
:focus {
  outline: none;
}
::-moz-focus-inner {
  border: 0;
}
h1 {
  padding: 0;
  margin: 2% 0 0 0;
  width: 49%;
  font-size: 1.5em;
  font-weight: bold;
  color: #000000;
  font-family: Arial, Trebuchet MS, Helvetica, sans-serif;
  float: left;
}
h2 {
  padding: 0;
  margin: 0;
  width: 100%;
  font-size: 1.3em;
  font-weight: bold;
  color: #000000;
  font-family: Arial, Trebuchet MS, Helvetica, sans-serif;
  float: left;
}
h3 {
  padding: 0;
  margin: 0;
  font-size: 1.2em;
  font-weight: bold;
  color: #000000;
  font-family: Arial, Trebuchet MS, Helvetica, sans-serif;
}
h4 {
  padding: 1% 0 2% 6%;
  margin: 0;
  font-size: 1.1em;
  line-height: 100%;
  font-family: Arial, Trebuchet MS, Helvetica, sans-serif;
}
h5 {
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 70%;
  font-weight: lighter;
  font-family: Arial, Trebuchet MS, Helvetica, sans-serif;
  color: #000000;
}
h6 {
  padding: 0;
  margin: 7% 0 0 0;
  font-size: 120%;
  font-weight: bold;
  font-family: Arial, Trebuchet MS, Helvetica, sans-serif;
  color: #000000;
}
p {
  color: #333333;
  margin: 0 0 1% 0;
  padding: 0% 2% 2% 0%;
  width: 98%;
  font-size: 1em;
  line-height: 175%;
  text-align: left;
  float: left;
  font-family: Arial, Trebuchet MS, Helvetica, sans-serif;
}
p a:link {
  font-size: 100%;
  line-height: 175%;
  color: #297171;
  text-decoration: underline;
  outline: none;
  background: none;
  transition: 0.1s linear;
}
p a:visited {
  list-style: none;
  color: #297171;
  text-decoration: underline;
  outline: none;
  background: none;
}
p a:hover {
  list-style: none;
  color: #5ed6d6;
  text-decoration: underline;
  outline: none;
  background: none;
}
p a:active {
  list-style: none;
  color: #5ed6d6;
  text-decoration: underline;
  outline: none;
  background: none;
}

/*************************** END OF CSS FOR GENERAL STYLING ******************************/

/******************************* CSS FOR BUTTONS STYLING  ******************************/

.buttonscontainer {
  margin: 0 1% 1% 1%;
  padding: 0;
  width: 48%;
  float: right;

  &.left {
    float: left;
  }
}
@media screen and (max-width: 901px) {
  .buttonscontainer {
    margin-top: 5px;
  }
}
@media screen and (max-width: 768px) {
  .buttonscontainer {
    padding: 2% 0;
    width: 99.5%;
    float: none;
    clear: both;

    &:after {
      content: "";
      display: block;
      height: 1px;
      clear: both;
      visibility: hidden;
    }
  }
}

.buttons ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 1em;
  font-weight: bold;
  float: left;
}
.buttons li {
  list-style: none;
  display: inline;
}
.buttons li a {
  color: #ffffff;
}
.buttons a:link,
.buttons a:visited,
.button, input[type="submit"] {
  margin: 0 0 0 1%;
  width: 27%;
  color: #ffffff;
  text-decoration: none;
  text-align: center;
  float: left;
  outline: none;
  border: solid 1px #4babab;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  padding: 1.2% 2%;
  font-weight: bold;
  text-decoration: none;
  background: #4babab; /* Old browsers - GLJ changed to #4bababa */
  background: -moz-linear-gradient(
    top,
    #dce3e3 0%,
    #a9d3d3 40%,
    #4babab 41%,
    #4babab 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #dce3e3),
    color-stop(50%, #a9d3d3),
    color-stop(51%, #4babab),
    color-stop(100%, #4babab)
  );
  background: -webkit-linear-gradient(
    top,
    #dce3e3 0%,
    #a9d3d3 40%,
    #4babab 51%,
    #4babab 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    #dce3e3 0%,
    #a9d3d3 40%,
    #4babab 51%,
    #4babab 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    top,
    #dce3e3 0%,
    #a9d3d3 40%,
    #4babab 51%,
    #4babab 100%
  ); /* IE10+ */
  background: linear-gradient(
    to bottom,
    #dce3e3 0%,
    #a9d3d3 40%,
    #4babab 41%,
    #4babab 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#dce3e3', endColorstr='#4babab', GradientType=0); /* IE6-9 */
  text-shadow: 1px 1px 0 #555555;
}
.buttons a:hover,
a:active, .button:hover, input[type="submit"]:hover {
  cursor: pointer;
  background: #4babab; /* Old browsers - GLJ changed to #4bababa*/
  background: -moz-linear-gradient(
    top,
    #c2c8c8 0%,
    #90b8b9 40%,
    #2b8f90 41%,
    #2b8f90 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #c2c8c8),
    color-stop(40%, #90b8b9),
    color-stop(51%, #2b8f90),
    color-stop(100%, #2b8f90)
  );
  background: -webkit-linear-gradient(
    top,
    #c2c8c8 0%,
    #90b8b9 40%,
    #2b8f90 51%,
    #2b8f90 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    #c2c8c8 0%,
    #90b8b9 40%,
    #2b8f90 51%,
    #2b8f90 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    top,
    #c2c8c8 0%,
    #90b8b9 40%,
    #2b8f90 51%,
    #2b8f90 100%
  ); /* IE10+ */
  background: linear-gradient(
    to bottom,
    #c2c8c8 0%,
    #90b8b9 40%,
    #2b8f90 41%,
    #2b8f90 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c2c8c8', endColorstr='#2b8f90', GradientType=0); /* IE6-9 */
  color: #888888;
  text-shadow: 1px 1px 0 #c7ffff;
}
.yellow_button_container {
  margin: 5% 10% 1% 1%;
  padding: 0;
  width: 89%;
  float: right;
}
.yellow_button {
  margin: 0%;
  padding: 0;
  width: 100%;
}
.yellow_button ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 1em;
  font-weight: bold;
}
.yellow_button li {
  list-style: none;
  display: inline;
}
.yellow_button li a {
  color: #002342;
}
.yellow_button a:link,
.yellow_button a:visited {
  margin: 0 0 0 1%;
  width: 95%;
  color: #002342;
  text-decoration: none;
  text-align: center;
  float: left;
  outline: none;
  border: solid 2px #002342;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  padding: 3%;
  font-weight: bold;
  text-decoration: none;
  background: -moz-linear-gradient(top, #fff0c5 0%, #ffb400 35%, #ffb400 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #fff0c5),
    color-stop(35%, #ffb400),
    color-stop(100%, #ffb400)
  );
  background: -webkit-linear-gradient(
    top,
    #fff0c5 0%,
    #ffb400 35%,
    #ffb400 100%
  );
  background: -o-linear-gradient(top, #fff0c5 0%, #ffb400 35%, #ffb400 100%);
  background: -ms-linear-gradient(top, #fff0c5 0%, #ffb400 35%, #ffb400 100%);
  background: linear-gradient(to bottom, #fff0c5 0%, #ffb400 35%, #ffb400 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff0c5', endColorstr='#ffb400', GradientType=0);
  text-shadow: none;
}
.yellow_button a:hover,
.yellow_button a:active {
  outline: none;
  background: #dce3e3;
  background: -moz-linear-gradient(top, #e5d7b1 0%, #e5a100 35%, #e5a100 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #e5d7b1),
    color-stop(35%, #e5a100),
    color-stop(100%, #e5a100)
  );
  background: -webkit-linear-gradient(
    top,
    #e5d7b1 0%,
    #e5a100 35%,
    #e5a100 100%
  );
  background: -o-linear-gradient(top, #e5d7b1 0%, #e5a100 35%, #e5a100 100%);
  background: -ms-linear-gradient(top, #e5d7b1 0%, #e5a100 35%, #e5a100 100%);
  background: linear-gradient(to bottom, #e5d7b1 0%, #e5a100 35%, #e5a100 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e5a100', endColorstr='#2b8f90', GradientType=0);
}
.bright_button {
  margin: 1% 0 0 2%;
  padding: 0;
  width: 75%;
  box-sizing: border-box;
  display: block;
}
.bright_button ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 1em;
  font-weight: bold;
}
.bright_button li {
  list-style: none;
  display: inline;
}
.bright_button li a {
  color: #002342;
}
.bright_button a:link,
.bright_button a:visited {
  margin: 0 2% 4% 2%;
  padding: 2% 1%;
  width: 86%;
  color: #002342;
  text-decoration: none;
  text-align: center;
  float: left;
  outline: none;
  border: solid 2px #002342;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  font-weight: bold;
  text-decoration: none;
  background: -moz-linear-gradient(top, #fff0c5 0%, #ffb400 35%, #ffb400 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #fff0c5),
    color-stop(35%, #ffb400),
    color-stop(100%, #ffb400)
  );
  background: -webkit-linear-gradient(
    top,
    #fff0c5 0%,
    #ffb400 35%,
    #ffb400 100%
  );
  background: -o-linear-gradient(top, #fff0c5 0%, #ffb400 35%, #ffb400 100%);
  background: -ms-linear-gradient(top, #fff0c5 0%, #ffb400 35%, #ffb400 100%);
  background: linear-gradient(to bottom, #fff0c5 0%, #ffb400 35%, #ffb400 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff0c5', endColorstr='#ffb400', GradientType=0);
  text-shadow: none;
}
.bright_button a:hover,
.bright_button a:active {
  outline: none;
  background: #dce3e3;
  background: -moz-linear-gradient(top, #e5d7b1 0%, #e5a100 35%, #e5a100 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #e5d7b1),
    color-stop(35%, #e5a100),
    color-stop(100%, #e5a100)
  );
  background: -webkit-linear-gradient(
    top,
    #e5d7b1 0%,
    #e5a100 35%,
    #e5a100 100%
  );
  background: -o-linear-gradient(top, #e5d7b1 0%, #e5a100 35%, #e5a100 100%);
  background: -ms-linear-gradient(top, #e5d7b1 0%, #e5a100 35%, #e5a100 100%);
  background: linear-gradient(to bottom, #e5d7b1 0%, #e5a100 35%, #e5a100 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e5a100', endColorstr='#2b8f90', GradientType=0);
}
.buy_button {
  margin: 0 0 8% 0;
  padding: 0;
  width: 100%;
  color: #002342;
  text-align: center;
}
.buy_button a:link,
.buy_button a:visited {
  margin: 0% 0% 2% 0%;
  color: #002342;
  text-decoration: none;
  text-align: center;
  outline: none;
  border: solid 2px #002342;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  padding: 3% 20%;
  font-weight: bold;
  text-decoration: none;
  background: -moz-linear-gradient(top, #fff0c5 0%, #ffb400 35%, #ffb400 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #fff0c5),
    color-stop(35%, #ffb400),
    color-stop(100%, #ffb400)
  );
  background: -webkit-linear-gradient(
    top,
    #fff0c5 0%,
    #ffb400 35%,
    #ffb400 100%
  );
  background: -o-linear-gradient(top, #fff0c5 0%, #ffb400 35%, #ffb400 100%);
  background: -ms-linear-gradient(top, #fff0c5 0%, #ffb400 35%, #ffb400 100%);
  background: linear-gradient(to bottom, #fff0c5 0%, #ffb400 35%, #ffb400 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff0c5', endColorstr='#ffb400', GradientType=0);
  text-shadow: none;
}
.buy_button a:hover,
.buy_button a:active {
  outline: none;
  background: #dce3e3;
  background: -moz-linear-gradient(top, #e5d7b1 0%, #e5a100 35%, #e5a100 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #e5d7b1),
    color-stop(35%, #e5a100),
    color-stop(100%, #e5a100)
  );
  background: -webkit-linear-gradient(
    top,
    #e5d7b1 0%,
    #e5a100 35%,
    #e5a100 100%
  );
  background: -o-linear-gradient(top, #e5d7b1 0%, #e5a100 35%, #e5a100 100%);
  background: -ms-linear-gradient(top, #e5d7b1 0%, #e5a100 35%, #e5a100 100%);
  background: linear-gradient(to bottom, #e5d7b1 0%, #e5a100 35%, #e5a100 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e5a100', endColorstr='#2b8f90', GradientType=0);
}
.side_nav_button {
  margin: 30px auto 0;
  padding: 0;
  float: left;
  clear: both;
  width: 100%;
}
.side_nav_button ul {
  width: 50%;
  margin: 0 auto;
  padding: 0;
  list-style: none;
  font-size: 1em;
  font-weight: bold;
}
.side_nav_button li {
  list-style: none;
  display: block;
}
.side_nav_button li a {
  color: #002342;
}
.side_nav_button a:link,
.side_nav_button a:visited {
  display: block;
  margin: 0 0 5px 0;
  padding: 5%;
  width: 93%;
  color: #002342;
  text-decoration: none;
  text-align: center;
  outline: none;
  border: solid 2px #002342;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  font-weight: bold;
  text-decoration: none;
  background: -moz-linear-gradient(top, #fff0c5 0%, #ffb400 35%, #ffb400 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #fff0c5),
    color-stop(35%, #ffb400),
    color-stop(100%, #ffb400)
  );
  background: -webkit-linear-gradient(
    top,
    #fff0c5 0%,
    #ffb400 35%,
    #ffb400 100%
  );
  background: -o-linear-gradient(top, #fff0c5 0%, #ffb400 35%, #ffb400 100%);
  background: -ms-linear-gradient(top, #fff0c5 0%, #ffb400 35%, #ffb400 100%);
  background: linear-gradient(to bottom, #fff0c5 0%, #ffb400 35%, #ffb400 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff0c5', endColorstr='#ffb400', GradientType=0);
  text-shadow: none;
}
.side_nav_button a:hover,
.side_nav_button a:active {
  outline: none;
  background: #dce3e3;
  background: -moz-linear-gradient(top, #e5d7b1 0%, #e5a100 35%, #e5a100 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #e5d7b1),
    color-stop(35%, #e5a100),
    color-stop(100%, #e5a100)
  );
  background: -webkit-linear-gradient(
    top,
    #e5d7b1 0%,
    #e5a100 35%,
    #e5a100 100%
  );
  background: -o-linear-gradient(top, #e5d7b1 0%, #e5a100 35%, #e5a100 100%);
  background: -ms-linear-gradient(top, #e5d7b1 0%, #e5a100 35%, #e5a100 100%);
  background: linear-gradient(to bottom, #e5d7b1 0%, #e5a100 35%, #e5a100 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e5a100', endColorstr='#2b8f90', GradientType=0);
}
.side_nav_pic {
  margin: 0;
  padding: 0;
  max-width: 262px;
}
.side_nav_pic img {
  margin: 10% 0;
  padding: 0;
  max-width: 100%;
  border: none;
  outline: none;
}

.side_nav_pic.bottom img {
  margin-bottom: 0px;
}

@media only screen and (max-width: 901px) {
  .side_nav_pic {
    display: none;
  }

  .side_nav_button {
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto !important;
    padding: 20px 20px 0;

    * {
      box-sizing: border-box;
    }

    ul {
      &:after {
        content: "";
        display: block;
        height: 1px;
        clear: both;
      }

      li {
        display: block;
        float: left;
        width: calc(50% - 15px);

        &:first-child {
          margin-right: 30px;
        }

        a {
          width: 100% !important;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .side_nav_button {
    ul {
      width: 100%;
      li {
        width: 47.49%;
        margin: auto;
        float: left;

        &:first-child {
          margin-right: 5%;
        }
      }
    }
  }
}

/******************    END OF CSS FOR BUTTONS STYLING    *****************  */

/******************   BITS AND PIECES ************************************/

.line_space  /*review page*/ {
  margin: 1% 0 1.5% 0;
  padding: 0;
  width: 98%;
  background-color: #4babab;
  height: 2px;
  clear: both;
}
.fb_centre {
  margin: 3% auto;
  padding: 0;
  width: 100%;
}
.text_width /*individual pages with no images*/ {
  margin: 0;
  padding: 0;
  width: 64%;
  overflow: none;
  float: left;
}
.text_width p /*individual pages with no images*/ {
  margin: 0;
  padding: 0 0 1.5% 0;
}
.text_width p img {
  margin: 1% 1% 0 0;
  padding: 0 0 1% 1%;
  float: right;
}
@media only screen and (max-width: 667px) {
  .text_width {
    width: 98%;
  }
}
.error {
  margin: 0;
  padding: 0;
  width: 70%;
}

/******************   BITS AND PIECES ************************************/

/******************************* CSS on per page basis for buttons ******************************/

#courses,
#tutorials,
#quizzes,
#tutorials_howto,
#x-ray_osce_presentation_tips,
#chest_osce_check {
  .previous, .next, .end, .middle-button /** top button still works on these pages **/
 {
    display: none;
  }
}
#raag_review,
#contact {
  .buttonscontainer {
    display: none;
  }
}
#galleries {
  .galleries_thums_wrapper {
    display: none;
  }
  .previous,
  .next,
  .end {
    display: none;
  }
}
#tutorials,
#tutorials_howto,
#galleries {
  #breadcrumb {
    display: none;
  }
}
#courses,
#tutorials,
#galleries,
#quizzes,
#ct_brain_course_more_info,
#trauma_whole_more_info,
#trauma_axial_more_info,
#trauma_append_more_info {
  aside > .homenav {
    // display: none;
  }
  .side_nav_button {
    margin: 30px auto;
  }
}
#galleries,
#quizzes {
  .side_nav_button a {
    border: solid 2px #339999;
  }
}
#galleries {
  .side_nav_button {
    margin-top: 0;
  }
}

.adsbygoogle {
  height: 100% !important;
  margin: auto;
  padding: 1rem 0;
  clear: both;
}

.left-aside {
	.share-links {
		padding: 20px;
	}
}

/******************************* CSS on per page basis for buttons ******************************/
