.authors-block {
    clear: both;
    padding-top: 20px;
    margin: 0% 1% 1% 1%;

    p {
        padding: 0;
        margin: 0;
        font-size: 0.7em;
    }

    
    .author-title, .last-reviewed-date{
        text-decoration: none;
        color: #666666;
    }

    a.author-title:hover .author-title {
        color: #333333;
    }

    
    .author-link {
        color: #297171;
        text-decoration: underline;
    }

}