
#fb_container {
	margin: 5% 0;
	padding: 2% 0 0 0;
	width: 100%;
	clear: both;
}
/*.fb_link a:link, .fb_link a:visited {
	display: block;
	margin: 2% 0;
	padding: 2%;
	color: #ffffff;
	text-align:center;
	text-decoration: none;
	font-size: .8em;
	background: #4babab;
	border-bottom: 1px solid #297171;
	border-right: 1px solid #297171;
	font-weight: normal;
	outline: none;
	transition: 0.1s linear;
}
.fb_link a:hover, .fb_link a:active {
	background-color: #5ed6d6;
	outline: none;
}
*/
.fb_image a:link, .fb_image a:visited {
	display: block;
	margin: 2% auto;
	padding: 1%;
	width: 40%;
	border: none;
	outline:none;
	background: url(/images/main_imgs/fb_link_a.png) no-repeat;
	background-size: 90%;
	background-position: center;
	height: 100px;
}
.fb_image a img{
	display: block;
	max-width: 100%;
}
.fb_image a:hover, .fb_image a:active {
	outline:none;
	display: block;
	background: url(/images/main_imgs/fb_link_b.png) no-repeat;
	background-size: 90%;
	background-position: center;
	height: 100px;
}
@media only screen and (max-width:901px) {
	#fb_container {
		display: none;
	}
}

.footer_box_inner {
	display: block;
	width: 100%;
}
.share-links {
	padding: 1rem;
	text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

	#st-3, #st-2 {
		z-index: 1;
	}

	.sharethis-inline-share-buttons {
		min-width: 300px;
	}

	h3 {
		display: none;
		text-align: center;
	}
}

.newscol {
	.share-links {
		display: block;
	}
}

/*****************   GLJ'S FACEBOOK CSS   *************/