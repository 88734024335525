// /********************** CSS FOR MAIN NAVIGATION    ********************/



.toggle-main {
	position: absolute; 
	top: 20px;
	right: 20px;
	background: transparent;
	padding: .25rem;
	height: 30px;
	z-index: 0;
	display: block;

		&:after {
			content: '';
			height: 1px;
			display: block;
			clear: both;
		}

		&:hover {
			cursor: pointer;
			opacity: 0.8;

			.icon {
				&:after, &:before {
				}
			}
		}

		.menu-toggle-text {
			width: calc(100% - 40px);
			display: block;
			float: left;
			padding-right: 10px;
		}



		.icon {
			display: block;
			height: 5px;
			width: 30px;
			background: #fff;
			float:left;
			position: relative;
			margin-top: 10px;
			transition: all ease .5s;

			&:hover {
				cursor: pointer;
				background: #fff;

				&:after, &:before {
					background: #fff;
				}
			}

			&:before {
				content: '';
				display: block;
				width: 30px;
				height: 5px;
				position: absolute;
				background: #fff;
				top: -10px;

			}
			&:after {
				content: '';
				display: block;
				width: 30px;
				height: 5px;
				position: absolute;
				background: #fff;
				bottom: -10px;

			}


		}

		&.animate {
			position: fixed; 
			top: 20px;
			right: 20px;
			z-index: 101;

			.icon {
				animation: navIcon 0.8s forwards;

				&:after {
					visibility: visible;
					animation: navIconTop 0.8s forwards;
				


				}	

				&:before{
					visibility: visible;
					animation: navIconBottom 0.8s forwards;					

				}

			}

		}
}

@-webkit-keyframes navIcon {
	30% {
		visibility: hidden;
		
	}
	100% {
		visibility: hidden;
	}
}


@-webkit-keyframes navIconTop {
	30% {
		visibility: visible;
		bottom: 0;
		transform: rotate(0deg);
	}

	100% {
		bottom: 0;
		transform: rotate(45deg);
	}
}

@-webkit-keyframes navIconBottom{

	30% {
		visibility: visible;
		top: 0;
		transform: rotate(0deg);
	}

	100% {
		top: 0;
		transform: rotate(-45deg);
	}
}



.mainnav {
	position: fixed;
	height: 100%;
	width: 100%;
	background: rgba(#00c6c6, 0.95);
	overflow: scroll;
	top: -150vh;
	transition: all .5s ease; 
	z-index: 100;

	&.animate {
		top: 0vh;
		transition: all .5s ease; 

	}

	.inner {
		width: 100%;
		margin-top: 60px;
		margin-bottom: 40px;

		li {
			width: 100%;

			a {
				color: #fff;
				display: block;
				width: 100%;
				text-align: left;
				padding: 1rem;
				text-decoration: none;

				&:hover, &.active {
					background: #fff;
					color: #339999;
				}
			}
		}
	}
}


@media only screen and (min-width:801px) {
	.toggle-main {
		display: none;
	}

	.mainnav {
	display: block;
	background: #00c6c6;
	position: static;
	margin-top: 0;
	width:calc(15px + 100%);
	height: 0;


	.inner {
		max-width: 900px;
		display: block;
		margin: auto;
		height: 36px;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top:109px;
		overflow: hidden;

		li {
			display: block; 
			width: calc(100%/7);
			float:left;

			a {
					background: #00c6c6; /* Old browsers */
					background: -moz-linear-gradient(top, #00c6c6 0%, #297171 41%); /* FF3.6+ */ 
					background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #00c6c6), color-stop(41%, #297171)); /* Chrome,Safari4+ */
					background: -webkit-linear-gradient(top, #00c6c6 0%, #297171 41%); /* Chrome10+,Safari5.1+ */
					background: -o-linear-gradient(top, #00c6c6 0%, #297171 41%); /* Opera 11.10+ */
					background: -ms-linear-gradient(top, #00c6c6 0%, #297171 41%); /* IE10+ */
					background: linear-gradient(to bottom, #00c6c6 0%, #297171 41%); /* W3C */
					filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00c6c6', endColorstr='#297171', GradientType=0); /* IE6-9 */
					box-sizing: border-box;
					padding: .5rem;
					border-right: 1px solid #297171;
					border-top: 2px solid #9bf1f1;
					border-radius: 7px 7px 0 0;
					display: block;
					width: 100%;
					text-decoration: none;
					text-align: center;

					&:first-child {
						border-left: 1px solid #ffffff;
					}

					&:hover, &#active {
							background: #00c6c6; /* Old browsers */
							background: -moz-linear-gradient(top, #9bf1f1 0%, #ffffff 34%); /* FF3.6+ */
							background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #9bf1f1), color-stop(34%, #ffffff)); /* Chrome,Safari4+ */
							background: -webkit-linear-gradient(top, #9bf1f1 0%, #ffffff 34%); /* Chrome10+,Safari5.1+ */
							background: -o-linear-gradient(top, #9bf1f1 0%, #ffffff 34%); /* Opera 11.10+ */
							background: -ms-linear-gradient(top, #9bf1f1 0%, #ffffff 34%); /* IE10+ */
							background: linear-gradient(to bottom, #9bf1f1 0%, #ffffff 34%); /* W3C */
							filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9bf1f1', endColorstr='#ffffff', GradientType=0); /* IE6-9 */
							border-top: 2px solid #ffffff;
							color: #339999;
							border-right: 1px solid #339999;
					}

			}
		}
	}
}
}



