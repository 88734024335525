/******************************** MEDIA QUERIES *******************************/

/********************************* HOME *********************************/
@media only screen and (max-width:667px) {
.homemenubox_courses, .homemenubox_tutorials, .homemenubox_gall {
	width: 100%;
}
}
/********************************* HOME *********************************/

/******************************** CONTENT *******************************/
@media screen and (max-width:901px) {
#content {
	width: 100%;
}
.big_screen {
	display: none;
}
.tablet {
	margin: 4px 3px;
	padding: 0;
	font-size: .7em;
	line-height: .7em;
	display: block;
}
h3 {
	font-size: 1.1em;
}
p {
	font-size: .9em;
}
.boxlegend h4, .boxlegend_2 h4, .keypoints h4 {
	font-size: 1em;
}
}
@media only screen and (max-width:768px) {
h1 {
	width: 98%;
}
h3 {
	margin: 2% 0% 0% 0%;
}
.boxlegend h4, .boxlegend_2 h4, .keypoints h4, .boxlegend_narrow_list h4  {
	padding: 2% 1% 1% 2%;
	font-size: .9em;
}
.boxlegend ul, .boxlegend_2 ul, .keypoints ul   {
	padding: 0 1% 1% 3%;
	font-size: .8em;
}
.boxlegend_narrow_list ul {
	padding: 0 1% 1% 3%;
	font-size: .8em;
}
}
@media only screen and (max-width:667px) {
.boxlegend h4, .boxlegend_2 h4, .keypoints h4, .boxlegend_narrow_list h4  {
	padding: 2% 1% 1% 2%;
	font-size: .8em;
}
.boxlegend ul, .boxlegend_2 ul, .keypoints ul, .boxlegend_narrow_list ul  {
	padding: 0 1% 0 3%;
	font-size: .7em;
}
}
@media only screen and (max-width:497px) {
p {
	width: 100%;
}
article {
	width: 98%;
}
h3 {
	margin: 2% 0 0 0;
}
.image_container {
	margin: 0 0 1% 0;
	padding: 0;
	width: 100%;
	float: left;
	clear: both;
}
.boxlegend, .boxlegend_2, .keypoints, .boxlegend_narrow_list   {
	width: 100%;
	margin: 0 0 1% 0;
	padding: 0 0 2% 0;
	border: none;
}
.boxlegend h4, .boxlegend_2 h4, .keypoints h4, .boxlegend_narrow_list h4  {
	font-size: 1em;
}
.boxlegend ul, .boxlegend_2 ul, .keypoints ul, .boxlegend_narrow_list ul  {
	font-size: .9em;
}
}
/******************************** CONTENT *******************************/


/******************************** TUTORIALS *******************************/
@media only screen and (max-width:768px) {
.menubox {
	margin: .8% 0;
	padding: 0;
	width: 98%;
	overflow: none;
	float: left;
	border: 1px solid #4babab;
}
.menubox h3{
	margin:0;
	padding: .5% 0 .5% 1%;
	font-size: 1em;
	font-weight:bold;
	background-color: #4babab;
	background-image:url(/images/main_imgs/hmblu_drk_bkgd.jpg);
	background-repeat:repeat-x;
	color: #ffffff;
}
}
/******************************** TUTORIALS *******************************/

/******************************** OTHER *******************************/
@media only screen and (max-width:1024px) {
.infobox {
	width: 98%;
}
}
@media only screen and (max-width:768px) {
.infobox {
	margin-bottom: 3%;
}
}
@media only screen and (max-width:667px) {
.newscol {
	display:none;
}
.infobox {
	float: none;
	width: 99%;
}
.top_gall_info	{
	float: none;
	width: 99%;
}
.brain_menubox, .chest_menubox, .msk_menubox, .chest_menubox, .abdo_menubox, .general_menubox {
	width: 99%;
}
.courses_info	{
	float: none;
	width: 99%;
}
.tutorials_info	{
	float: none;
	width: 99%;
}
.gallmenubox_brain h2, .gallmenubox_trauma h2, .gallmenubox_chest h2, .gallmenubox_abdo h2 {
	padding: 1% 1% .5% 4%;
	width: 95%;
}
.test_menubox_brain h2, .test_menubox_trauma h2, .test_menubox_chest h2, .test_menubox_abdo h2, {
	margin:0 0 5px 0;
	padding: 1% 1% .5% 4%;
	width: 95%;	
}
.test_info	{
	float: none;
	width: 99%;
}
.test_info li {
	margin: 0 0 3% 6%;
 	padding: 0 1% 0 0; 
}
.price_box {
	float: none;
	width: 100%;
}
.price_box a img{
	width:  80%;
}
.courses_top	{
	padding: 1%; 
	width: 98%;
}
.courses_top_info	{
	padding: 1% 0 0 0; 
	width: 100%;
}
.courses_top_image {
	display: none;
}
}
/******************************** OTHER *******************************/

/******************************** GALLERIES *****************************/

@media only screen and (max-width:901px) {
.gallmenubox_brain, .gallmenubox_trauma, .gallmenubox_chest, .gallmenubox_abdo {
	width: 100%;
}
.gallinfo li {
	margin: 0 0 0 3%;
 	padding: 1% 2% 3% 0%; 
 }
}
@media only screen and (max-width:667px) {
.scroll {
	display:none;
}
.gallinfo	{
	font-size: .9em;
	width:96%;
}
.galldescrip	{
	font-size: .9em;
	width: 96%;
}
.galldescrip p {
	padding: 0 1% 1% 1%;
}
.bright_button{
	margin: 0 auto;
	padding:0;
	width: 100%;
	float: left;
}
.bright_button a:link, .bright_button a:visited, .bright_button a:hover, .bright_button a:active  {
	margin: 1% 1% 4% 1%;
	width: 66%;
}
}

/******************************** GALLERIES *****************************/


/****************************** TEST YOURSELF *****************************/
@media only screen and (max-width:901px) {
aside > .homenav {
	margin-top: 0px;
}
.test_menubox_brain, .test_menubox_trauma, .test_menubox_chest, .test_menubox_abdo {
	width: 100%;
}
}
@media only screen and (max-width:768px) {
.scriptcontainer {
	margin: 0 0 2% 0;
	padding: 0;
	width: 100%;
}
.clin_legend {
	margin: 0 auto;
	width: 98%;
	background-color: #297171;
	border-top: none;
	border-left: none;
	border-bottom: none;
	border-right: none;
}
}

/****************************** TEST YOURSELF *****************************/