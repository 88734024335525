/******************    BODY AND HEADER LAYOUT    *****************  */
body {
	margin: 0 auto;
	width: 100%;
	font-family: Arial, "Century Gothic", Helvetica, sans-serif;
	font-size: 1em;
	background-color: #333333;
	background-image: url(/images/main_imgs/body_bckgd.png);
	background-repeat: repeat-x;
	background-attachment: fixed;
	position: relative;
	z-index: -1;
}

#wrapper {
	margin: 0 auto;
	width: 1200px;
	overflow: hidden;
	background-image: url(/images/main_imgs/bckdrepeater.png);
	background-repeat: repeat-y;
	background-position: left;
	background-color: #ffffff;
	position: relative;
	z-index: -1;
}
header {
	margin: 0;
	padding: 0;
	width: 100%;
	position: relative;
}
.banner {
	float: left;
	width: 100%;
	background: rgba(155, 241, 241, 1);
	background: -moz-linear-gradient(
		top,
		rgba(155, 241, 241, 1) 0%,
		rgba(155, 241, 241, 1) 29%,
		rgba(0, 198, 198, 1) 64%,
		rgba(0, 198, 198, 1) 100%
	);
	background: -webkit-gradient(
		left top,
		left bottom,
		color-stop(0%, rgba(155, 241, 241, 1)),
		color-stop(29%, rgba(155, 241, 241, 1)),
		color-stop(64%, rgba(0, 198, 198, 1)),
		color-stop(100%, rgba(0, 198, 198, 1))
	);
	background: -webkit-linear-gradient(
		top,
		rgba(155, 241, 241, 1) 0%,
		rgba(155, 241, 241, 1) 29%,
		rgba(0, 198, 198, 1) 64%,
		rgba(0, 198, 198, 1) 100%
	);
	background: -o-linear-gradient(
		top,
		rgba(155, 241, 241, 1) 0%,
		rgba(155, 241, 241, 1) 29%,
		rgba(0, 198, 198, 1) 64%,
		rgba(0, 198, 198, 1) 100%
	);
	background: -ms-linear-gradient(
		top,
		rgba(155, 241, 241, 1) 0%,
		rgba(155, 241, 241, 1) 29%,
		rgba(0, 198, 198, 1) 64%,
		rgba(0, 198, 198, 1) 100%
	);
	background: linear-gradient(
		to bottom,
		rgba(155, 241, 241, 1) 0%,
		rgba(155, 241, 241, 1) 29%,
		rgba(0, 198, 198, 1) 64%,
		rgba(0, 198, 198, 1) 100%
	);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9bf1f1', endColorstr='#00c6c6', GradientType=0 );
	margin: 0;
	padding: 0;
	height: 145px;

	&:after {
		content: "";
		display: block;
		visibility: hidden;
		clear: both;
		width: 1px;
	}

	a:active {
		background: none;
	}
}

.logo {
	margin: 0;
	padding: 0;
	width: 320px;
	max-width: 50%;
	border: none;
	outline: none;
}
.icon {
	margin: 0;
	padding: 0;
	max-width: 100%;
	height: auto;
	min-width: 30px;
}
.search_icon {
	display: none;
}

.search_box {
	margin: 3% 10% 0 0;
	padding: 0;
	max-width: 44%;
	position: absolute;
	top: 0px;
	right: 0;
}
#breadcrumb {
	padding: 0;
	margin: 0;
	width: 100%;
	background-color: #ffffff;
	text-align: left;
}
#breadcrumb ul {
	padding: 8px 12px 9px 12px;
	margin: 0;
	font-size: 0.8em;
}
#breadcrumb li {
	display: inline;
	list-style-type: none;
	padding: 0 0 0 5px;
	color: #4babab;
	font-weight: bold;
	margin: 0;
	outline: none;
}
#breadcrumb li a:link {
	color: #5ed6d6;
	font-weight: bold;
	text-decoration: underline;
	outline: none;
	transition: 0.1s linear;
	background: none;
}
#breadcrumb li a:visited {
	color: #5ed6d6;
	font-weight: bold;
	text-decoration: underline;
	outline: none;
	background: none;
}
#breadcrumb li a:hover {
	color: #fcd001;
	font-weight: bold;
	text-decoration: underline;
	outline: none;
	background: none;
}
#breadcrumb li a:active {
	color: #5ed6d6;
	font-weight: bold;
	text-decoration: underline;
	outline: none;
	background: none;
}
.breadcrumb_bold {
	padding: 0;
	margin: 0;
	font-weight: bold;
	color: #297171;
}
.breadcrumb_bold_hilight {
	padding: 0 2px 0 0;
	margin: 0;
	font-weight: bold;
	color: #297171;
}
.breadcrumb_bold_lolight {
	padding: 0 5px 0 0;
	margin: 0;
	font-weight: bold;
	color: #5ed6d6;
}

/******************    END BODY AND HEADER LAYOUT    ******************/

/****************   MEDIA QUERIES FOR BODY AND HEADER LAYOUT  **************/
@media screen and (max-width: 1200px) {
	#wrapper {
		width: 100%;
		margin: 0;
		padding: 0;
	}
}
@media screen and (max-width: 901px) {
	#wrapper {
		background-image: none;
	}
	#breadcrumb {
		// display: none;
	}
}
@media screen and (max-width: 800px) {
	.banner {
		height: auto;
	}
}

@media screen and (max-width: 768px) {
	.search_icon {
		position: absolute;
		right: 90px;
		top: 20px;
		padding: 0;
		max-width: 30px;
		height: auto;
		display: block;

		figure {
			margin: 0;
		}
	}
	.search_box {
		display: none;
	}

	.yellow_button_container {
		margin: 3% 8% 2% 1%;
		padding: 0;
		width: 91%;
	}
}
@media only screen and (max-width: 413px) {
	.logo {
		padding-top: 5px;
	}
	.toggle-main {
		position: absolute;
		top: 15px;
		right: 15px;
	}
	.search_icon {
		right: 90px;
		top: 15px;
	}
}
/****************   END OF MEDIA QUERIES FOR BODY AND HEADER LAYOUT  **************/
