// change breakpoint for the search popup
//change search icon to be cross on popup
/**************************  CSS FOR SEARCH STYLING  ******************************/
input[type="search"] {
	-webkit-appearance: none;
	-webkit-border-radius: 0;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
	-webkit-appearance: none;
}
.search_box {
	width: 250px;
	border-top: 1px solid #4babab;
	border-right: 1px solid#ffffff;
	border-bottom: 1px solid#ffffff;
	border-left: 1px solid #4babab;
}
.search {
	width: 85%;
	padding: 2px 0 2px 4px;
	color: #fff;
	background: transparent;
	line-height: 1.2em;
	font-size: 1em;
	border: 0px;
}
.search::-webkit-input-placeholder {
	color: #fff;
}
.search:-moz-placeholder {
	color: #fff;
}
.search::-moz-placeholder {
	color: #fff;
}
.search:-ms-input-placeholder {
	color: #fff;
}
.search_box {
	.submit {
		position: absolute;
		right: 5px;
		top: 50%;
		transform: translateY(-50%);
		height: 15px;
		width: 15px;
		background: transparent;

		figure {
			margin: 0;
		}
	}
}
.submit img:hover {
	cursor: pointer;
}
.submit img {
	width: 15px;
}
.overlay {
	display: none;
	width: 90%;
	left: 5%;
	background-color: #fff;
	opacity: 1;
	top: 109px;
	z-index: 100;
	position: absolute;
	padding: 35px 20px 20px;
	box-sizing: border-box;
}
.overlay .submit {
	height: 44px;
	width: 44px;
	margin: 2px 9px 1px 0;
	background: transparent;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 5px;
}
.overlay .submit img {
	width: 30px;
}
.overlay .search-wrapper {
	position: relative;
	width: 100%;

	figure {
		margin: 0;
	}
}
.overlay .search {
	width: 100%;
	height: 47px;
	background: #00c6c6;
	line-height: 23px;
	font-size: 23px;
	margin-top: 0px;
	padding-right: 47px;
	box-sizing: border-box;
}
.overlay .close {
	position: absolute;
	top: 5px;
	right: 5px;
	figure {
		margin: 0;
	}
	img {
		height: 20px;
		width: 20px;
	}
}
.overlay .close:hover {
	cursor: pointer;
}

@media screen and (min-width: 845px) {
	.overlay {
		display: none !important;
	}
}
/***************************** END OF CSS FOR SEARCH ************************/
