/******************************** CONTENT LAYOUT *******************************/
strong {
  font-weight: bold;
}

sub {
  font-size: .5em;
}

em {
  font-style: italic;
}
#content {
  margin: 0 0 2% 0;
  float: right;
  width: 78%;
  background-color: #ffffff;
  position: relative;
  z-index: -1;

  &:after {
    content: "";
    height: 1px;
    width: 1px;
    clear: both;
    visibility: hidden;
  }
}
.content-wrapper {
  clear: both;
}

.maintext_container {
  margin: 0 1% 1% 1%;
  padding: 0;
  width: 98%;
  float: left;
}
.image_container {
  margin: 0 0 3% 0;
  padding: 0;
  width: 50%;
  float: left;
  clear: both;
}
a.imageswap_0,
a.imageswap_1,
a.imageswap_2,
a.imageswap_3,
a.imageswap_4,
a.imageswap_5,
a.imageswap_6,
a.imageswap_7,
a.imageswap_8,
a.imageswap_9,
a.imageswap_10 {
  float: left;
  width: 100%;
  height: 0;
  background-position: 0% 0%;
  background-size: 200%;
  display: block;
  border: none;
  cursor: crosshair;
}
a.imageswap_0:hover,
a.imageswap_1:hover,
a.imageswap_2:hover,
a.imageswap_3:hover,
a.imageswap_4:hover,
a.imageswap_5:hover,
a.imageswap_6:hover,
a.imageswap_7:hover,
a.imageswap_8:hover,
a.imageswap_9:hover,
a.imageswap_10:hover {
  background-position: 100% 0;
  background-size: 200%;
  border: none;
}

.big_screen {
  margin: 4px 0 5px 3px;
  padding: 0;
  font-size: 0.7em;
  line-height: 0.7em;
  display: block;
}
.tablet {
  display: none;
}
article {
  margin: 0;
  padding: 0;
  width: 50%;
  overflow: none;
  float: left;
}
.keypoints,
.boxlegend,
.boxlegend_2,
.boxlegend_narrow_list {
  margin: 0 2% 1% 0;
  padding: 0.5% 0;
  width: 47%;
  color: #ffffff;
  float: right;
  font-size: 1em;
  line-height: 120%;
  text-align: left;
}
.keypoints {
  margin: 0 2% 0 0;
}
.keypoints ul,
.boxlegend ul,
.boxlegend_2 ul {
  list-style: none;
  padding: 1% 4% 1% 3%;
  margin: 0;
}
.keypoints li,
.boxlegend li,
.boxlegend_2 li {
  list-style: url(/images/main_imgs/squ_bull_3.gif);
  padding: 1px 0 0 0;
  margin: 0 0 3px 12px;
}
.keypoints h4,
.boxlegend h4,
.boxlegend_2 h4,
.boxlegend_narrow_list h4 {
  padding: 1% 1% 1% 2%;
  margin: 0;
  font-size: 110%;
  font-weight: bold;
  line-height: 100%;
}
.keypoints a:link,
.keypoints a:visited,
.boxlegend a:link,
.boxlegend a:visited,
.boxlegend_2 a:link,
.boxlegend_2 a:visited {
  list-style: none;
  padding: 1px 0 2px 0;
  margin: 0;
  color: #ffffff;
  text-decoration: underline;
  outline: none;
  transition: 0.1s linear;
  background: none;
}
.keypoints a:hover,
.keypoints a:active,
.boxlegend a:hover,
.boxlegend a:active,
.boxlegend_2 a:hover,
.boxlegend_2 a:active {
  color: #5ed6d6;
}
.keypoints p,
.boxlegend p,
.boxlegend_2 p {
  padding: 0px 10px 7px 27px;
  margin: 0;
  font-size: 100%;
  line-height: 160%;
  color: #ffffff;
}
.keypoints,
.boxlegend_2 {
  background-color: #339999;
  border-top: solid #00c6c6 1px;
  border-left: solid #00c6c6 1px;
  border-bottom: solid #297171 1px;
  border-right: solid #297171 1px;
}
.boxlegend,
.boxlegend_narrow_list {
  background-color: #297171;
  border-top: solid #2ababa 1px;
  border-left: solid #2ababa 1px;
  border-bottom: solid #174040 1px;
  border-right: solid #174040 1px;
}

/*******************************/

.boxlegend_narrow_list ul {
  padding: 1% 1% 1% 3%;
  margin: 0;
  width: 46%;
  float: left;
}
.boxlegend_narrow_list li {
  list-style: url(/images/main_imgs/squ_bull_3.gif);
  padding: 1px 0 0 0;
  margin: 0 0 3px 12px;
}
.boxlegend_narrow_list p {
  padding: 0px 10px 7px 27px;
  margin: 0;
  font-size: 100%;
  line-height: 160%;
}
.narrowlisttext {
  margin: 0;
  padding: 0 0 0 15px;
  font-size: 90%;
  line-height: 110%;
  list-style-type: none;
}
.narrowlisttext ul {
  margin: 10px 0 20px 0;
  padding: 0;
  width: 50%;
  float: left;
  list-style-type: none;
}
.narrowlisttext li {
  margin: 0 0 10px 0;
  padding: 0;
  width: 100%;
  float: left;
  list-style-type: none;
}
.narrowlisttext a {
  margin: 0;
  padding: 0;
  color: #297171;
  text-decoration: underline;
  outline: none;
}
.narrowlisttext a:visited {
  margin: 0;
  padding: 0;
  color: #777777;
  text-decoration: underline;
  outline: none;
}
.narrowlisttext a:hover {
  margin: 0;
  padding: 0;
  color: #fcd001;
  text-decoration: underline;
  outline: none;
}
.narrowlisttext a:active {
  margin: 0;
  padding: 0;
  color: #fcd001;
  text-decoration: underline;
  outline: none;
}
.listtext {
  margin: 0;
  padding: 0 0 0 15px;
  font-size: 90%;
  line-height: 110%;
  list-style-type: none;
}
.listtext ul {
  margin: 10px 0 25px -13px;
  padding: 0;
  width: 99%;
  float: left;
  list-style-type: none;
}
.listtext li {
  margin: 0 0 10px 0;
  padding: 0;
  width: 100%;
  float: left;
  list-style-type: none;
  font-size: 1rem;
}
.listtext a {
  margin: 0;
  padding: 0;
  color: #297171;
  text-decoration: underline;
  outline: none;
}
.listtext a:visited {
  margin: 0;
  padding: 0;
  color: #297171;
  text-decoration: underline;
  outline: none;
}
.listtext a:hover {
  margin: 0;
  padding: 0;
  color: #00c6c6;
  text-decoration: underline;
  outline: none;
}
.listtext a:active {
  margin: 0;
  padding: 0;
  color: #00c6c6;
  text-decoration: underline;
  outline: none;
}

.red	/*subheading*/		 {
  padding: 0;
  margin: 0;
  font-weight: bold;
  color: #ff0000;
}
.red a:link a:visited a:hover a:active {
  margin: 0;
  padding: 0;
  color: #ff0000;
  text-decoration: underline;
  font-weight: bold;
  outline: none;
}

/******************************** CONTENT LAYOUT *******************************/

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
