#covid-19-case-submission {
    #covid-form-error {
        color: red;
    }

  .covid-form {
    box-sizing: border-box;
    padding-left: 1%;
    width: 100% - 2%;
    @media only screen and (min-width: 768px) {
      padding-right: 40%;
      width: 100%;
    }
  }
  .dropzone-wrapper {
    margin-bottom: 20px;

    p {
      float: none;
      padding-bottom: 0;
      font-weight: 700;
    }

    .covid-dropzone {
      display: block;
      width: 100%;
      border: 2px dashed #297171;
    }
  }
}
